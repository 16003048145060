import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
registerLocaleData(localeId, 'id');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopNavBarComponent } from './components/top-nav-bar/top-nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { BeritaComponent } from './components/berita/berita.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfilDesaComponent } from './pages/profil-desa/profil-desa.component';
import { BeritaDesaComponent } from './pages/berita-desa/berita-desa.component';
import { StatistikKependudukanComponent } from './pages/statistik-kependudukan/statistik-kependudukan.component';
import { BeritaCardComponent } from './components/berita-card/berita-card.component';
import { PotensiDesaComponent } from './pages/potensi-desa/potensi-desa.component';
import { HttpClientModule} from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LembagaComponent } from './components/lembaga/lembaga.component';
import { BumdesComponent } from './components/bumdes/bumdes.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AparatDesaWidgetComponent } from './components/aparat-desa-widget/aparat-desa-widget.component';
import { VisiMisiDesaComponent } from './pages/visi-misi-desa/visi-misi-desa.component';
import { ProfilComponent } from './components/profil/profil.component';
import { MainJumbotronComponent } from './components/main-jumbotron/main-jumbotron.component';
import { ArsipComponent } from './pages/arsip/arsip.component';
import { AgendaKerjaComponent } from './pages/agenda-kerja/agenda-kerja.component';
import { Covid19Component } from './pages/covid19/covid19.component';

@NgModule({
  declarations: [
    AppComponent,
    TopNavBarComponent,
    FooterComponent,
    BeritaComponent,
    HomeComponent,
    ProfilDesaComponent,
    BeritaDesaComponent,
    StatistikKependudukanComponent,
    BeritaCardComponent,
    PotensiDesaComponent,
    LembagaComponent,
    BumdesComponent,
    AparatDesaWidgetComponent,
    VisiMisiDesaComponent,
    ProfilComponent,
    MainJumbotronComponent,
    ArsipComponent,
    AgendaKerjaComponent,
    Covid19Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    NgbModule.forRoot(),
    AngularFontAwesomeModule
  ],
  providers: [  { provide: LOCALE_ID, useValue: "id-ID" }],
  bootstrap: [AppComponent]
})
export class AppModule { }
