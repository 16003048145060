import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api-service.service';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-agenda-kerja',
  templateUrl: './agenda-kerja.component.html',
  styleUrls: ['./agenda-kerja.component.css']
})
export class AgendaKerjaComponent implements OnInit {

  loading: boolean;
  dataTable: any[];
	totalRecords: number
  column: any;
  sort: any;
  previousPage:number;
	page: number;
  rows: number;
  api: string;

  constructor(private apiService:ApiService) { }

  ngOnInit() {
    this.api=Config.get().apiBackend;

    this.page = Config.get().page;
    this.rows = Config.get().rows;
    this.column = 'TglTampil'
    this.sort = 'desc';

    this.getDataTable(this.column, this.sort, this.page, this.rows);
  }

  getDataTable(column,sort,page,rows) {
    this.loading = true
    console.log("AGENGASFSKFDJASFHASHFASHFLSALDJASLKDJL");
		this.apiService.get(Config.get().apiBackend+'service/public/konten-public/agenda-kerja?dir='+column+'&sort='+sort+'&page='+page+'&rows='+rows).subscribe(res=>{
      this.dataTable = res.data
      console.log(res);
			this.totalRecords = res.totalRow
			this.loading = false
		})
  }

  getExtension(data:string){
    if(data){
      return data.split('.').pop();
    }
  }

}
