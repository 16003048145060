import { Component } from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import { Config } from 'src/app/config/config';
import { Router } from '@angular/router';
import { ApiService } from './service/api-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Desa Berekah';

  DataArsip: any[];

  constructor(private titleService: Title, private meta:Meta, private router: Router, private apiService:ApiService){
    titleService.setTitle('Profile Web Desa '+ Config.get().namaDesa);

    meta.addTag({name:'viewport', content:'width=device-width, initial-scale=1, shrink-to-fit=no'});
    meta.addTag({name:'description', content:'web desa'});
    meta.addTag({name:'author', content:'narendra m a'});

  }

  ngOnInit() {
    this.apiService.get(Config.get().apiBackend+'/service/public/konten-public/arsip-index').subscribe(res=>{
      this.DataArsip = res.data;
    })
  }

}
