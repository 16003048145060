import { Component, OnInit, Injectable } from '@angular/core';
import { Config } from 'src/app/config/config';
import { ApiService} from '../../service/api-service.service';

@Component({
  selector: 'app-potensi-desa',
  templateUrl: './potensi-desa.component.html',
  styleUrls: ['./potensi-desa.component.css']
})
export class PotensiDesaComponent implements OnInit {

  loading: boolean;
  dataTable: any[];
	totalRecords: number
  column: any;
	sort: any;
	page: number;
  rows: number;
  Tanggal: any;

  constructor(private apiService:ApiService) { }

  ngOnInit() {
    this.Tanggal = new Date();
    this.page = Config.get().page;
    this.rows = Config.get().rows;
    this.column = 'TglTampil'
    this.sort = 'desc';

    this.getDataTable(this.column, this.sort, this.page, this.rows);
  }

  getDataTable(column,sort,page,rows) {
		this.loading = true
		this.apiService.get(Config.get().apiBackend+'service/public/konten-public/potensi-desa?dir='+column+'&sort='+sort+'&page='+page+'&rows='+rows).subscribe(res=>{
			this.dataTable = res.data
			this.totalRecords = res.totalRow
			this.loading = false
		})
  }

}
