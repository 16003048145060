import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api-service.service';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-berita',
  templateUrl: './berita.component.html',
  styleUrls: ['./berita.component.css']
})
export class BeritaComponent implements OnInit {

  private routeSub: Subscription;
  private data:any;
  private id:number;
  private pengarang:string;
  private Api:string = Config.get().apiBackend;

  constructor(private route: ActivatedRoute, private apiService:ApiService) { }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      //console.log(params['id']) //log the value of id
      this.id=params['id'];
      this.apiService.get(Config.get().apiBackend+'service/public/konten-public/berita/'+params['id']).subscribe(res=>{
        this.data = res.data
        if(res.data.CreateBy == null){
          this.pengarang="Anonim"
        } else {
          this.pengarang=res.data.CreateBy;
        }
      })
    });
  }

}
