import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BeritaComponent } from './components/berita/berita.component';
import { HomeComponent } from './pages/home/home.component';
import { BeritaDesaComponent } from './pages/berita-desa/berita-desa.component';
import { ProfilDesaComponent } from './pages/profil-desa/profil-desa.component';
import { StatistikKependudukanComponent } from './pages/statistik-kependudukan/statistik-kependudukan.component';
import { PotensiDesaComponent } from './pages/potensi-desa/potensi-desa.component';
import { LembagaComponent } from './components/lembaga/lembaga.component';
import { BumdesComponent } from './components/bumdes/bumdes.component';
import { ProfilComponent } from './components/profil/profil.component';
import { VisiMisiDesaComponent } from './pages/visi-misi-desa/visi-misi-desa.component';
import { ArsipComponent } from './pages/arsip/arsip.component';
import { AgendaKerjaComponent } from './pages/agenda-kerja/agenda-kerja.component';
import { Covid19Component } from './pages/covid19/covid19.component';

const routes: Routes = [
  {
    path: '',
    component:BeritaDesaComponent //HomeComponent
  },
  {
    path: 'profil-desa',
    component:ProfilDesaComponent
  },{
    path: 'visi-misi',
    component:VisiMisiDesaComponent
  },
  {
    path: 'agenda-kerja',
    component:AgendaKerjaComponent
  },
  // {
  //   path: 'berita-desa',
  //   component:BeritaDesaComponent
  // },
  {
    path: 'arsip/:jenisArsip',
    component:ArsipComponent
  },
  {
    path: 'berita-desa/:id',
    component:BeritaComponent
  },
  {
    path: 'lembaga/:id',
    component:LembagaComponent
  },
  {
    path: 'bumdes/:id',
    component:BumdesComponent
  },{
    path: 'profil/:id',
    component:ProfilComponent
  },
  {
    path: 'statistik-kependudukan',
    component:StatistikKependudukanComponent
  },
  {
    path: 'potensi-desa',
    component:PotensiDesaComponent
  },
  {
    path: 'covid-19',
    component:Covid19Component
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
