import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-berita-card',
  templateUrl: './berita-card.component.html',
  styleUrls: ['./berita-card.component.css']
})
export class BeritaCardComponent implements OnInit {

  @Input() data:any;

  Api:string

  urlDetail:string;

  constructor(private router: Router) {}

  ngOnInit() {
    this.Api = Config.get().apiBackend;
    this.urlDetail = Config.get().home+"/berita-desa";
  }

}
