import { Component, OnInit, Injectable } from '@angular/core';
import { Config } from 'src/app/config/config';
import { ApiService} from '../../service/api-service.service';
import {NgxPaginationModule} from 'ngx-pagination';

@Component({
  selector: 'app-berita-desa',
  templateUrl: './berita-desa.component.html',
  styleUrls: ['./berita-desa.component.css']
})
export class BeritaDesaComponent implements OnInit {

  loading: boolean;
  dataTable: any[];
	totalRecords: number
  column: any;
  sort: any;
  previousPage:number;
	page: number;
  rows: number;
  Tanggal: any;

  constructor(private apiService:ApiService) { }

  ngOnInit() {
    this.Tanggal = new Date();
    this.page = Config.get().page;
    this.rows = Config.get().rows;
    this.column = 'TglTampil'
    this.sort = 'desc';

    this.getDataTable(this.column, this.sort, this.page, this.rows);
  }

  loadPage(page){
    console.log(page +"Total"+this.rows);
    if (page !== this.previousPage) {
      this.page = page;
      this.getDataTable(this.column, this.sort, this.page, this.rows);
      window.scrollTo(0, 550);
    }
  }

  getDataTable(column,sort,page,rows) {
		this.loading = true
		this.apiService.get(Config.get().apiBackend+'service/public/konten-public/berita?dir='+column+'&sort='+sort+'&page='+page+'&rows='+rows).subscribe(res=>{
			this.dataTable = res.data
			this.totalRecords = res.totalRow
			this.loading = false
		})
  }



}
