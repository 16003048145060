import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api-service.service';
import { Config } from 'src/app/config/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.css']
})
export class TopNavBarComponent implements OnInit {

  bumdes:any[];
  bumdesReady:boolean;
  lembaga:any[];
  lembagaReady:boolean;
  profil:any[];
  profilReady:boolean;
  covid19Ready;
  homeUrl:string;

  constructor(private apiService:ApiService, private router: Router) { }

  ngOnInit() {
    this.lembagaReady=false;
    this.bumdesReady=false;
    this.profilReady=false;
    this.covid19Ready=false;
    this.homeUrl=location.origin;

    this.getBumdes();
    this.getLembaga();
    this.getProfil();
    this.getCovid19();
  }

  getProfil() {
		this.apiService.get(Config.get().apiBackend+'service/public/konten-public/profil-dropdown').subscribe(res=>{
      this.profil=res.data;
			if(res.data.length()>0){
        this.profilReady=true;
      }
		})
  }

  getBumdes() {
		this.apiService.get(Config.get().apiBackend+'service/public/konten-public/bumdes-dropdown').subscribe(res=>{
      this.bumdes=res.data;
      if(res.data.length()>0){
        this.bumdesReady=true;
      }
		})
  }

  getLembaga() {
		this.apiService.get(Config.get().apiBackend+'service/public/konten-public/lembaga-dropdown').subscribe(res=>{
      this.lembaga=res.data;
			if(res.data.length()>0){
        this.lembagaReady=true;
      }
		})
  }

  getCovid19() {
		this.apiService.get(Config.get().apiBackend+'service/develop/data-covid/cek').subscribe(res=>{
      console.log(res);
			if(res.data.IsView>0){
        this.covid19Ready=true;
      }
		})
  }



}
