import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api-service.service';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-main-jumbotron',
  templateUrl: './main-jumbotron.component.html',
  styleUrls: ['./main-jumbotron.component.css']
})
export class MainJumbotronComponent implements OnInit {

  GambarHeadingHome:string
  Api:string
  DataLink: any[]

  constructor(private apiService:ApiService) { }

  ngOnInit() {
    this.Api=Config.get().apiBackend
    this.getGambarHeading();
  }

  getGambarHeading() {
		this.apiService.get(Config.get().apiBackend+'/service/develop/profile-web-desa/heading-home').subscribe(res=>{
      this.GambarHeadingHome = Config.get().apiBackend+res.data.GambarHeadingHome;
    })

    this.apiService.get(Config.get().apiBackend+'/service/develop/link-external/all-public').subscribe(res=>{
      this.DataLink = res.data;
      console.log(res.data)
    })
  }

}
