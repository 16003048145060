import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/config/config';
import { ApiService } from 'src/app/service/api-service.service';

@Component({
  selector: 'app-visi-misi-desa',
  templateUrl: './visi-misi-desa.component.html',
  styleUrls: ['./visi-misi-desa.component.css']
})
export class VisiMisiDesaComponent implements OnInit {

  Visi:string
  Misi:string

  constructor(private apiService:ApiService) { }

  ngOnInit() {
    this.getDataTable();
  }

  getDataTable() {
		this.apiService.get(Config.get().apiBackend+'/service/develop/profile-web-desa/visi-misi').subscribe(res=>{
      this.Visi = res.data.Visi;
      this.Misi = res.data.Misi
		})
  }

}
