import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api-service.service';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-aparat-desa-widget',
  templateUrl: './aparat-desa-widget.component.html',
  styleUrls: ['./aparat-desa-widget.component.css']
})
export class AparatDesaWidgetComponent implements OnInit {

  data: any[];

  adminServer:string

  images = [185, 186, 187].map((n) => `https://picsum.photos/id/${n}/300/400.jpg`);

  constructor(private apiService:ApiService) { }

  ngOnInit() {
    this.adminServer=Config.get().apiBackend;
    this.apiService.get(Config.get().apiBackend+'service/public/profile-aparat-desa/public').subscribe(res=>{
      this.data = res.data;
      console.log(this.data);
    })
  }

}
