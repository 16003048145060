import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api-service.service';
import { Subscription } from 'rxjs';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-arsip',
  templateUrl: './arsip.component.html',
  styleUrls: ['./arsip.component.css']
})
export class ArsipComponent implements OnInit {

  private routeSub: Subscription;
  loading: boolean;
  JenisArsip:string
  Data:any[]
  page: number;
  rows: number;
  totalRecords: number
  previousPage:number;

  constructor(private route: ActivatedRoute, private apiService:ApiService) { }

  ngOnInit() {

    this.page = Config.get().page;
    this.rows = 10;

    this.routeSub = this.route.params.subscribe(params => {
      this.JenisArsip=params['jenisArsip'];
      this.getDataTable(this.page, this.rows);
    })

  }

  loadPage(page){
    console.log(page +"Total"+this.rows);
    if (page !== this.previousPage) {
      this.page = page;
      this.getDataTable(this.page, this.rows);
      window.scrollTo(0, 0);
    }
  }

  getDataTable(page:number, rows:number){
    this.loading=true
    this.apiService.get(Config.get().apiBackend+'service/public/konten-public/arsip-menu/'+this.JenisArsip+'/'+page+'/'+rows).subscribe(res=>{
      this.Data = res.data
      this.totalRecords = res.totalRow
    })
    this.loading=false
  }

}
