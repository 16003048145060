import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api-service.service';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-lembaga',
  templateUrl: './lembaga.component.html',
  styleUrls: ['./lembaga.component.css']
})
export class LembagaComponent implements OnInit {

  private routeSub: Subscription;
  private data:any;
  private id:number;
  private Api:string = Config.get().apiBackend;

  constructor(private route: ActivatedRoute, private apiService:ApiService) { }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      console.log(params['id']) //log the value of id
      this.id=params['id'];
      this.apiService.get(Config.get().apiBackend+'service/public/konten-public/lembaga/'+params['id']).subscribe(res=>{
        this.data = res.data
      })
    });
  }

}
