export class Config {
  static get() {
    return {
      home: "http://localhost:4200",
      //home: "http://web.radanka.com",
      apiBackend: "http://localhost:8000/",
      //apiBackend: 'http://api.radanka.com/',
      page: 1,
      rows: 10,
      namaDesa: "Berekah",
    };
  }
}
