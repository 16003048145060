import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/config/config';
import { ApiService } from 'src/app/service/api-service.service';

@Component({
  selector: 'app-statistik-kependudukan',
  templateUrl: './statistik-kependudukan.component.html',
  styleUrls: ['./statistik-kependudukan.component.css']
})
export class StatistikKependudukanComponent implements OnInit {

  data:any

  dataKelompokUmur:any[]

  constructor(private apiService:ApiService) { }

  ngOnInit() {
    this.apiService.get(Config.get().apiBackend+'service/develop/statistik/penduduk-terkini').subscribe(res=>{
      this.data = res.data;
      //console.log(this.data);
    })

    this.apiService.get(Config.get().apiBackend+'/service/develop/statistik/kelompok-golongan-usia').subscribe(res=>{
      this.dataKelompokUmur = res.data;
      console.log(this.data);
    })
  }

}
