import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/config/config';
import { ApiService } from 'src/app/service/api-service.service';

@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: ['./covid19.component.css']
})
export class Covid19Component implements OnInit {

  dataTerdampak: any
  dataPasien: any

  constructor(private apiService:ApiService) { }

  ngOnInit() {
    this.apiService.get(Config.get().apiBackend+'service/develop/data-covid/pasien').subscribe(res=>{
      this.dataPasien = res.data;
    })

    this.apiService.get(Config.get().apiBackend+'service/develop/data-covid/terdampak').subscribe(res=>{
      this.dataTerdampak = res.data;
    })
  }
}
